.userModulesWrapper {
  height: calc(100% - 2rem);
  width: 80%;
  margin: 1rem;
  background-color: #fff;
  border-radius: 20px;
  overflow-y: scroll;

  .btnHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
  }

  .btnWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    font-weight: 600;
    font-size: 1rem;

    .addUser {
      padding: 0.5rem 1rem;
      border-radius: 4px;
      background-color: #3752f6;
      color: #fff;
      cursor: pointer;
    }
  }

  .sideBtnContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }

  .backBtn {
    text-align: left;
    display: block;
    // padding: 0 2rem;
    color: #707070;
    cursor: pointer;
    text-decoration: underline;
    // padding-top: 2rem;
    transition: all 200ms ease;

    &:hover {
      font-weight: 700;
    }
  }

  .userDetailsBox {
    margin: 2rem;
    display: flex;
    padding: 2rem;
    gap: 2rem;
    border-radius: 24px;
    border: 1px solid rgba(0, 0, 0, 0.2);

    .leftBox {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      .userNameWrapper {
        display: flex;
        align-items: center;
        gap: 1rem;
        text-transform: capitalize;

        .userInitials {
          background-color: #3752f6;
          width: 3rem;
          display: grid;
          place-content: center;
          height: 3rem;
          border-radius: 50%;
          color: #fff;
          font-size: 1.5rem;
          font-weight: 600;
        }

        .username {
          font-size: 1.5rem;
          color: #000;
          font-weight: 600;
        }
      }
    }

    .rightBox {
      width: 70%;
      display: grid;
      row-gap: 1rem;
      column-gap: 1rem;
      grid-template-columns: 1fr 1fr 1fr;
      border-left: 1px solid rgba(0, 0, 0, 0.2);
      padding-left: 2rem;

      .inputWrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;

        .label {
          font-weight: 700;
        }
      }
    }
  }

  .table {
    width: 100%;
    padding: 1rem 4rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow-y: scroll;

    .userMap {
      width: 100%;
      display: flex;
      padding: 0 0.25rem;
      align-items: center;
      justify-content: space-between;
      gap: 2rem;
      border-bottom: 1px solid #c4c4c4;

      &:hover {
        background-color: rgba(0, 0, 0, 0.2);
      }

      div {
        padding: 1rem 0;
        min-width: 15%;
        max-width: 15%;
      }

      div {
        cursor: pointer;
        width: 10rem;
        text-align: left;
      }
    }

    .noSessions {
      font-weight: 500;
      margin-top: 2rem;
      align-self: center;
      width: 100%;
      text-align: center;
    }

    .heading {
      font-weight: 700;

      &:hover {
        background-color: transparent;
      }

      div {
        text-align: left;
      }
    }
  }

  .modalWrapper {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(10px);

    .modalOverlay {
      position: absolute;
      inset: 0;
      z-index: 10;
    }

    .modal {
      position: absolute;
      left: 50%;
      width: 80%;
      height: 80%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 10;
    }
  }
}