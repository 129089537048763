.LoginWrapper {
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .sidebar {
    height: 100%;
    background-color: #3752f6;
    width: 35vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 10rem 4rem;
    color: #fff;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      border-radius: 50%;
      width: 20rem;
      height: 20rem;
      border: 10px solid #fff;
      bottom: -13rem;
      right: -10rem;
    }
    .title {
      font-size: 2.5rem;
      font-weight: 800;
    }
  }
  .modalWrapper {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    .modalOverlay {
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 10;
      background-color: rgba(0, 0, 0, 0.2);
      backdrop-filter: blur(5px);
    }
    .modal {
      position: fixed;
      left: 50%;
      width: 40vw;
      border-radius: 20px;
      top: 50%;
      transform: translate(-50%, -50%);
      background-color: #fff;
      z-index: 15;
      backdrop-filter: blur(5px);
      padding: 2rem;
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 2rem;
      .mailImg {
        width: 5rem;
        height: 5rem;
        object-fit: contain;
      }
      .continue {
        background-color: #3752f6;
        color: #fff;
        margin-top: 2rem;
        display: grid;
        place-content: center;
        align-self: center;
        padding: 1rem 2rem;
        width: 8rem;
        height: 3rem;
        border-radius: 5px;
        border: none;
        cursor: pointer;
        transition: transform 500ms ease;
        &:active {
          transform: scale(0.9);
        }
      }
      .inputWrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: relative;
        .label {
          color: #000;
          font-weight: 600;
          margin-bottom: 0.5rem;
        }
        .countryCode {
          // background-color: #3752f6;
          color: #000;
          width: 3rem;
          height: 100%;
          display: grid;
          place-content: center;
          position: absolute;
          border-radius: 4px;
          top: 50%;
          left: 0;
          transform: translateY(-54%);
        }
        .input {
          border: none;
          width: 100%;
          border-radius: 4px;
          background-color: #e8e8e8;
          padding: 1rem 2rem;
          font-size: 1rem;
        }
        .errors {
          position: absolute;
          bottom: -1.5rem;
          color: #ff0000;
        }
      }
      .submitBtn {
        background-color: #3752f6;
        color: #fff;
        margin-top: 2rem;
        display: grid;
        place-content: center;
        align-self: center;
        padding: 1rem 2rem;
        width: 8rem;
        height: 3rem;
        border-radius: 5px;
        border: none;
        cursor: pointer;
        transition: transform 500ms ease;
        &:active {
          transform: scale(0.9);
        }
        .loader {
          width: 1rem;
          height: 1rem;
          border: 2px solid #fff;
          border-bottom-color: transparent;
          border-radius: 50%;
          display: inline-block;
          box-sizing: border-box;
          animation: rotation 1s linear infinite;
        }
      }
    }
  }
  .mainWrapper {
    width: 65vw;
    height: 100%;
    display: flex;
    align-items: flex-start;
    padding: 5rem 10rem;
    flex-direction: column;
    justify-content: space-between;
    .logo {
      width: 80%;
      object-fit: contain;
    }
    .formWrapper {
      width: 60%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: relative;
      gap: 3rem;
      .title {
        font-size: 3rem;
        font-weight: 800;
        color: #000;
      }
      .inputWrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: relative;
        .label {
          color: #000;
          font-weight: 600;
          margin-bottom: 0.5rem;
        }
        .countryCode {
          // background-color: #3752f6;
          color: #000;
          width: 3rem;
          height: 100%;
          display: grid;
          place-content: center;
          position: absolute;
          border-radius: 4px;
          top: 50%;
          left: 0;
          transform: translateY(-54%);
        }
        .input {
          border: none;
          width: 100%;
          border-radius: 4px;
          background-color: #e8e8e8;
          padding: 1rem 2rem;
          font-size: 1rem;
        }
        .errors {
          position: absolute;
          bottom: -1.5rem;
          color: #ff0000;
        }
      }
      .bottomLink {
        columns: #000;
        display: flex;
        align-items: center;
        .clickHere {
          cursor: pointer;
          margin-left: 0.5rem;
          font-weight: 600;
          color: #3752f6;
        }
      }
      .btnWrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
        .forgotPass {
          text-decoration: underline;
          cursor: pointer;
        }
        .loginBtn {
          background-color: #3752f6;
          color: #fff;
          padding: 1rem 2rem;
          width: 8rem;
          height: 3rem;
          display: grid;
          place-content: center;
          border-radius: 5px;
          border: none;
          cursor: pointer;
          transition: transform 500ms ease;
          &:active {
            transform: scale(0.9);
          }
          .loader {
            width: 1rem;
            height: 1rem;
            border: 2px solid #fff;
            border-bottom-color: transparent;
            border-radius: 50%;
            display: inline-block;
            box-sizing: border-box;
            animation: rotation 1s linear infinite;
          }
        }
      }
      .errors {
        position: absolute;
        bottom: -3rem;
        color: #ff0000;
      }
    }
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
