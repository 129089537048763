.navbarWrapper {
  height: 5rem;
  padding: 1rem 2rem;
  width: 100%;
  padding-left: 15vw;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  align-items: center;
  justify-content: space-between;
  .searchInput {
    width: 50%;
    height: 3rem;
    display: flex;
    align-items: center;
    border-radius: 10px;
    gap: 1rem;
    padding: 1rem;
    background-color: #e8e8e8;
    .svgBox {
      width: 1.5rem;
      height: 1.5rem;
      svg {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    input {
      background-color: #e8e8e8;
      height: 100%;
      width: 100%;
      border: none;
      &:focus {
        outline: none;
      }
    }
  }
  .logo {
    height: 4rem;
    object-fit: contain;
  }
  .modalWrapper {
    position: fixed;
    inset: 0;
    .overlay {
      position: fixed;
      inset: 0;
      z-index: 10;
    }
    .modal {
      position: absolute;
      z-index: 15;
      top: 4rem;
      right: 2rem;
      background-color: #fff;
      border: 1px solid #3752f6;
      font-weight: 500;
      cursor: pointer;
      border-radius: 5px;
      div {
        padding: 1rem 2rem;
        &:hover {
          background-color: rgba(0, 0, 0, 0.3);
        }
      }
    }
  }
  .userBox {
    cursor: pointer;
    display: grid;
    place-content: center;
    width: 2.5rem;
    height: 2.5rem;
    font-size: 1.5rem;
    border-radius: 50%;
    background-color: #3752f6;
    color: #fff;
  }
}
