.mainContainer {
  display: flex;
  padding: 1rem;
}

.allusersWrapper {
  background-color: #e8e8e8;
  width: 100%;
  height: 100%;
  padding: 2rem 3rem;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    font-size: 1.25rem;

    .headerRightSide {
      display: flex;
      gap: 1rem;
    }


    .btnWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 2rem;
      font-weight: 600;
      font-size: 1rem;

      .filterBtn {
        padding: 0.5rem 1rem;
        background-color: #fff;
        display: flex;
        align-items: center;
        color: #707070;
        border-radius: 4px;
        position: relative;
        padding-left: 2rem;

        .filterIcon {
          position: absolute;
          top: 50%;
          left: 1rem;
          width: 1rem;
          height: 0.5px;
          background-color: #707070;
          transform: translate(-50%, -3px);

          &::before {
            content: "";
            position: absolute;
            top: 3px;
            left: 50%;
            transform: translateX(-50%);
            height: 0.5px;
            background-color: #707070;
            width: 0.75rem;
          }

          &::after {
            content: "";
            position: absolute;
            top: 6px;
            left: 50%;
            transform: translateX(-50%);
            height: 0.5px;
            background-color: #707070;
            width: 0.5rem;
          }
        }
      }

      .addUser {
        padding: 0.5rem 1rem;
        border-radius: 4px;
        background-color: #3752f6;
        color: #fff;
        cursor: pointer;
      }
    }
  }

  .usersWrappers {
    display: flex;
    margin-top: 3rem;
    flex-direction: column;
    max-height: 80%;
    overflow-x: auto;

    .lineWrapperHeading {
      display: grid;
      padding: 1rem 0;
      text-align: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      font-weight: 700;
    }

    .noUsers {
      margin-top: 2rem;
      font-weight: 500;
      text-align: center;
    }

    .editBtn {
      position: absolute;
      bottom: 1rem;
      right: 3rem;
      gap: 2rem;
      width: 1.5rem;
      z-index: 2;
      height: 1.5rem;
      display: flex;
      // border-radius: 50%;
      // background-color: #efefff;
      justify-content: center;
      align-items: center;

      .doneBtn {
        background-color: #3752f6;
        color: #fff;
        padding: 0.25rem 1rem;
        margin-right: 2rem;
        cursor: pointer;
        border-radius: 6px;
      }

      img {
        width: 50%;
        height: 50%;
        object-fit: contain;
        cursor: pointer;
      }

      .deleteBtn {
        height: 90%;
        width: 90%;
      }

      .loader {
        width: 1rem;
        height: 1rem;
        border: 2px solid #fff;
        border-bottom-color: transparent;
        border-radius: 50%;
        display: inline-block;
        box-sizing: border-box;
        animation: rotation 1s linear infinite;
      }
    }

    .lineWrapper {
      display: grid;

      padding: 1rem 0;
      text-align: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      position: relative;
      transition: transform 500ms ease;

      &:hover {
        background: rgba(0, 0, 0, 0.2);
        cursor: pointer;
      }

      &.disabled {
        opacity: 0.4;
      }

      input {
        width: 100%;
        color: #000;
        margin: 0 auto;
        position: relative;
        z-index: 2;
        font-size: 0.95rem;
        border: 1px solid rgba(0, 0, 0, 0.2);
        background: none;

        &:focus {
          outline: none;
        }
      }

      input[type="number"]::-webkit-inner-spin-button,
      input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    .nextArrow {
      position: absolute;
      top: 1rem;
      right: 1rem;
      z-index: 2;
      width: 1.5rem;
      height: 1.5rem;
      display: flex;
      border-radius: 50%;
      background-color: #efefff;
      justify-content: center;
      align-items: center;

      img {
        width: 50%;
        height: 50%;
        object-fit: contain;
      }
    }

    .userCard {
      cursor: pointer;
      width: 18rem;
      height: 8rem;
      display: flex;
      padding: 1rem;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      // background-color: #fff;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 20px;
      transition: transform 500ms ease;
      position: relative;

      .userId {
        font-size: 0.75rem;
        font-weight: 500;
        display: flex;
        align-items: center;
        gap: 1rem;

        input {
          font-size: 0.75rem;
          border: none;
          background: none;

          &:focus {
            outline: none;
          }
        }

        input[type="number"]::-webkit-inner-spin-button,
        input[type="number"]::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }

      .name {
        font-size: 1.25rem;
        font-weight: 700;
      }

      .taskTime {
        font-size: 0.75rem;
        font-weight: 500;

        .userTime {
          color: #3752f6;
        }
      }
    }
  }

  .modalWrapper {
    position: fixed;
    inset: 0;
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(10px);

    .modalOverlay {
      position: absolute;
      inset: 0;
      z-index: 10;
    }

    .modal {
      position: absolute;
      left: 50%;
      width: 80%;
      min-height: 80%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 10;
    }
  }
}

.dataRow {
  display: grid;
  grid-template-columns: 1fr;

  gap: 1rem;
  padding: 1rem;

  .dataBlock {
    border: solid #3752f6 2px;
    border-radius: 0.75rem;
    font-size: 1.25rem;
    padding: 2rem 1rem;
    text-transform: uppercase;

    b {
      font-size: 1.5rem;
      color: #3752f6;
    }
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

:global(.datePicker) {
  :global(.rdrDefinedRangesWrapper) {
    display: none;
  }
}

.checkboxContainer {
  display: flex;
  align-items: center;
  
  margin: 1rem 0rem;


  justify-content: space-around;

  .checkboxItem {


    input {
      // opacity: 0;
      display: none;
    }

    label {
      background: #808080;
      padding: 1rem 2rem;
      border-radius: 0.75rem;

      color: #fff;
      font-weight: 700;
      display: block;
    }

    input:checked + label {
      background: #343494;
    }
  }
}