.sidebarWrapper {
  height: 100%;
  min-width: 15vw;
  max-width: 15vw;
  background-color: #fff;
  .list {
    padding: 2rem 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    .item {
      cursor: pointer;
      max-width: 80%;
      width: 80%;
      background-color: #3752f6;
      padding: 0.75rem 2rem;
      font-size: 1rem;
      border-radius: 10px;
      display: flex;
      align-items: center;
      gap: 1rem;
      color: #fff;
      .logo {
        width: 1rem;
        height: 1rem;
        object-fit: contain;
      }
    }
  }
}
