body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.appWrapperMobile {
  display: none;
}

@media screen and (max-width: 768px) {
  .appWrapper {
    display: none;
  }
  .appWrapperMobile {
    display: grid;
    place-content: center;
    width: 100vw;
    color: #fff;
    font-size: 2rem;
    font-weight: 700;
    padding: 2rem;
    text-align: center;
    background-color: #3752f6;
    height: 100vh;
  }
}
