.UserReportWrapper {
  width: 100%;
  overflow: hidden;
  background-color: #e8e8e8;
  .headingObjWrapper {
    display: flex;
    max-width: 90%;
    padding: 0 2rem;
    // margin: 0 auto;
    align-items: center;
    gap: 1rem;
    justify-content: space-between;
    .downloadBtn {
      margin-top: 2rem;
      width: 15rem;
      height: 5rem;
      background-color: #3752f6;
      padding: 1rem;
      color: #fff;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      text-align: center;
      border-radius: 4px;
      cursor: pointer;
    }
    .itemWrapper {
      margin-top: 2rem;
      width: 15rem;
      height: 5rem;
      background-color: #fff;
      padding: 1rem;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: 0.5rem;
      border-radius: 4px;
      border: 2px solid #3752f6;
    }
  }
  .perfomanceWrapperBox {
    width: 100%;
    .perfomanceWrapper {
      margin: 2rem;
      overflow-y: auto;
      max-height: 70vh;
      max-width: 90%;
      padding: 2rem;
      border-radius: 10px;
      background-color: #fff;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      .heading {
        font-weight: 700;
        font-size: 1.5rem;
      }
      .userWrapper {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        column-gap: 1rem;
        padding: 1rem 0;
        border-bottom: 1px solid #e8e8e8;
      }
    }
  }
}
