.formWrapper {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  padding: 2rem 3rem;

  .header {
    font-size: 1.5rem;
    font-weight: 700;
  }
  .inputsWrapper {
    padding: 2rem 1rem;
    display: grid;
    column-gap: 5rem;
    row-gap: 3rem;
    grid-template-columns: 1fr 1fr;
    font-weight: 500;

    .inputBox {
      margin-left: 1rem;
      align-self: flex-start;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      .inputWrapperPhone {
        align-self: flex-start;
        display: flex;
        width: 60%;
        align-items: center;
        justify-content: space-between;
        position: relative;
        .text {
          &:focus {
            outline: none;
          }
        }
        input {
          &:focus {
            outline: none;
          }
        }
        .countryCode {
          position: absolute;
          left: 0;
          display: grid;
          place-content: center;
          border-radius: 2px;
          background-color: #9cc1e5;
          width: 3rem;
          height: 100%;
        }
      }
      .text {
        width: 60%;
        border: 2px solid #9cc1e5;
        border-radius: 4px;
        padding: 1rem;
        &:focus {
          outline: none;
        }
      }
      .select {
        width: 60%;
        border: 2px solid #9cc1e5;
        border-radius: 4px;
        padding: 1rem;
        // margin: 0;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-appearance: none;
        -moz-appearance: none;
        background-image: linear-gradient(45deg, transparent 50%, blue 50%),
          linear-gradient(135deg, blue 50%, transparent 50%),
          linear-gradient(to right, skyblue, skyblue);
        background-position: calc(100% - 20px) calc(1.25rem + 2px),
          calc(100% - 15px) calc(1.25rem + 2px), 100% 0;
        background-size: 5px 5px, 5px 5px, 2.5rem 5rem;
        background-repeat: no-repeat;
        .options {
          background-color: #9cc1e5;
          padding: 4rem 2rem;
          height: 5rem;
        }
      }
      .number {
        width: 60%;
        border: 2px solid #9cc1e5;
        border-radius: 4px;
        padding: 1rem;
      }
      .date {
        width: 60%;
        border: 2px solid #9cc1e5;
        border-radius: 4px;
        padding: 1rem;
      }
      .errors {
        position: absolute;
        bottom: -1.25rem;
        left: 0%;
        color: #ff0000;
      }
    }

    .textArea {
      grid-column-start: 1;
      grid-row-start: 3;
      grid-row-end: 5;
      .textArea {
        width: 60%;
        height: 8rem;
        border: 2px solid #9cc1e5;
        border-radius: 4px;
        padding: 1rem;
      }
    }
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }
  }
  .submit {
    margin-top: 2rem;
    font-weight: 500;
    cursor: pointer;
    background-color: #3752f6;
    color: #fff;
    padding: 1rem 3rem;
    border-radius: 10px;
    align-self: center;
    .loader {
      width: 1rem;
      height: 1rem;
      border: 2px solid #fff;
      border-bottom-color: transparent;
      border-radius: 50%;
      display: inline-block;
      box-sizing: border-box;
      animation: rotation 1s linear infinite;
    }
  }
}

.modalWrapperInset {
  position: fixed;
  width: 100vw;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  height: 100vh;
  border-radius: 16px;
  z-index: 25;
  background-color: rgba(0, 0, 0, 0.5);
  .modal {
    position: fixed;
    z-index: 20;
    left: 50%;
    top: 50%;
    width: 40%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 2rem 3rem;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    .btnWrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 2rem;
      button {
        cursor: pointer;
        color: #fff;
        border: none;
        padding: 1rem 1.5rem;
        background-color: #3752f6;
        border-radius: 8px;
      }
    }
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
