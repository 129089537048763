.userDetailsWrapper {
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 3rem 2rem;
  border: 1.5px solid #253f9d;
  border-radius: 30px;

  .list {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    .noTask {
      font-weight: 500;
      margin-top: 2rem;
      text-align: center;
    }
    .userName {
      text-transform: capitalize;
      font-size: 1.5rem;
      font-weight: 600;
      margin-right: auto;
      text-align: left;
    }
    .subHeader {
      display: flex;
      align-items: center;
      font-weight: 600;
      justify-content: space-between;
      padding: 1rem 2rem;
      overflow-x: hidden;
      position: relative;
      .labelWrapper {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 2rem;
        .downloadBtn {
          border-radius: 6px;
          width: 14rem;
          display: flex;
          justify-content: space-between;
          color: #ffff;
          align-items: center;
          background-color: #3752f6;
          padding: 1rem;
          .downloadPng {
            width: 2rem;
            height: 2rem;
            object-fit: contain;
          }
        }
        .label {
          display: flex;
          align-items: center;
          gap: 2rem;
          div {
            width: 10rem;
            text-align: center;
          }
        }
      }

      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 98%;
        height: 1px;
        background-color: #707070;
      }
    }

    .itemWrapper {
      width: 100%;
      border-radius: 10px;
      display: flex;
      align-items: center;
      padding: 1rem 2rem;
      background-color: #f2f7fc;
      margin: 1rem 0;
      color: #707070;
      font-weight: 600;
      position: relative;
      justify-content: space-between;
      cursor: pointer;

      .itemName {
        display: flex;
        align-items: center;
        gap: 1rem;
        font-size: 1.15rem;
        .dropdownIcon {
          width: 1rem;
          position: relative;
          background-color: #e0e6ed;
          border-radius: 50%;
          height: 1rem;
          img {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%) rotate(90deg);
            width: 50%;
            height: 50%;
            object-fit: contain;
          }
        }
      }
      .label {
        display: flex;
        align-items: center;
        gap: 2rem;
        div {
          width: 10rem;
          text-align: center;
        }
      }
    }
    .extraDetailWrapper {
      background-color: #eaeef3;
      border-radius: 10px;
      .extraDetails {
        padding: 2rem 3rem;
        tr {
          td {
            padding: 0.5rem 1rem;
          }
        }
      }
    }
  }
}
