.homeWrapper {
  height: 100vh;
  width: 100%;
  display: flex;
  padding-top: 5rem;
  overflow-x: hidden;
  position: relative;
  justify-content: space-between;
  .loader {
    width: 1rem;
    height: 1rem;
    border: 2px solid #fff;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
  .mainMenu {
    width: 85vw;
    height: 100%;
  overflow: auto;
    background-color: #e8e8e8;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
